import React from "react";
import genKey from "_shared/genKey";
import { post } from "_shared/request";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";

const Star = ({
  page,
  TeamId,
  IsFav,
  event_type,
  PortalID,
  TabName,
  announcement_id,
  index,
  tab,
  updateContainer,
  MemberId,
  user,
  setUser,
  columnIndex,
  directReports,
  setDirectReports,
  setUpdateIsFav,
  setResourceFav,
  ResourceID,
  handleFav,
  style,
  isMobile,
  dashboard,
}) => {
  const BLOCK_LIST = [
    "APPLIED_CARE",
    "CORPORATE",
    "CORPORATE_APPLICATIONS",
    "CORPORATE_IT",
    "FACILITIES",
    "HEALTH_SERVICES",
    "PAYROLL",
    "PERSONNEL",
    "SAFETY",
  ];
  const { t } = useTranslation();
  const { addToast } = useToasts();
  let TOOLTIP_PAGE;

  switch (page) {
    case "Events":
      TOOLTIP_PAGE = "dashboard";
      break;
    case "Portals":
      TOOLTIP_PAGE = "favorites section & dashboard";
      break;
    case "PortalsDashboard":
      TOOLTIP_PAGE = "dashboard";
      break;
    case "AnnouncementTab":
      TOOLTIP_PAGE = "dashboard";
      break;
    case "IndivAnnouncement":
      TOOLTIP_PAGE = "favorites section";
      break;
    case "DirectoryProfile":
      TOOLTIP_PAGE = "dashboard";
      break;

    case "Directory":
      TOOLTIP_PAGE = "dashboard";
      break;
    case "IndivResource":
      TOOLTIP_PAGE = "favorites section & dashboard";
      break;
    case "ResourceDash":
      TOOLTIP_PAGE = "dashboard";
      break;
  }

  const handleSetFav = (link, isFav) => {
    let url;
    switch (page) {
      case "Events":
        url = `/events/types/${link}/${TeamId}/${event_type}`;
        break;
      case "Portals":
        url = `/portals/${link}/${TeamId}/${tab.PortalID}`;
        break;
      case "PortalsDashboard":
        url = `/portals/${link}/${TeamId}/${PortalID}`;
        break;
      case "AnnouncementTab":
        url = `/announcements/types/${link}/${TeamId}/${TabName}`;
        break;
      case "IndivAnnouncement":
        url = `/announcements/${link}/${TeamId}/${announcement_id}`;
        break;
      case "DirectoryProfile":
        url = `/directory/${link}/${TeamId}/${MemberId}`;
        break;
      case "Directory":
        url = `/directory/${link}/${TeamId}/${MemberId}`;
        break;
      case "IndivResource":
        url = `/resources/${link}/${TeamId}/${ResourceID}`;
        break;
      case "ResourceDash":
        url = `/resources/${link}/${TeamId}/${ResourceID}`;
        break;
    }
    const updateFav = async () => {
      try {
        await post({ url });
      } catch (e) {
        console.log(e);
      }
    };
    updateFav();
    if (page === "Directory" || page === "DirectoryProfile") {
      setUpdateIsFav(genKey());
    }

    const updateDirectoryDesktop = () => {
      if (
        !dashboard &&
        parseInt(directReports.EmployeeNumber) === user[index][columnIndex].id
      ) {
        const directReportProfile = { ...directReports };
        directReportProfile.isFav = isFav;
        setDirectReports(directReportProfile);
      }
    };
    switch (page) {
      case "Events":
        handleFav(isFav);
        break;
      case "Portals":
        const newElement = { ...tab, IsFav: isFav };
        updateContainer(newElement);
        break;
      case "PortalsDashboard":
        handleFav();
        break;
      case "AnnouncementTab":
        handleFav(isFav);
        break;
      case "IndivAnnouncement":
        handleFav(isFav);
        break;
      case "Directory":
        const newDirect = [...user];
        columnIndex
          ? (newDirect[index][columnIndex].value = isFav)
          : (newDirect[index].isFav = isFav);
        setUser(newDirect);
        isMobile ? () => {} : updateDirectoryDesktop();
        break;
      case "DirectoryProfile":
        const newDirectProfile = { ...directReports };
        newDirectProfile.isFav = isFav;
        setDirectReports(newDirectProfile);
        const newDirectory = [...user];
        columnIndex
          ? (newDirectory[index][columnIndex].value = isFav)
          : (newDirectory[index].isFav = isFav);
        break;
      case "IndivResource":
        setResourceFav(isFav);
        break;
      case "ResourceDash":
        handleFav(index);
        break;
    }
  };

  const handleStarClick = (bool) => {
    if (page === "AnnouncementTab" && BLOCK_LIST.indexOf(TabName) !== -1) {
      return;
    }
    if (event_type === "CORPORATE") {
      return;
    }
    if (bool) {
      handleSetFav("fav", true);
      addToast(t(`Item successfully added to `) + TOOLTIP_PAGE, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
        pauseOnhover: true,
      });
    } else {
      handleSetFav("unfav", false);
      addToast(t(`Item sucessfully removed from `) + TOOLTIP_PAGE, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
        pauseOnhover: true,
      });
    }
  };

  return (
    <div
      title={`Click here to ${IsFav ? "unfavorite" : "favorite"} item
      ${IsFav ? " from" : " to"} ${TOOLTIP_PAGE}`}
      // styleName="star-container"
      className="inline-block "
      data-cy="starToggleContainer"
    >
      {IsFav ? (
        <i
          alt="favorited"
          data-cy="unFavStar"
          className="fas fa-star visible relative float-right w-4 cursor-pointer text-star-filled"
          // styleName="star fav-star"
          onClick={() => {
            handleStarClick(false);
          }}
        >
          <span data-cy="starSR" className="sr-only">
            Unfavorite
          </span>
        </i>
      ) : (
        <i
          alt="unfavorited"
          data-cy="favStar"
          data-for={page}
          className="fas fa-star relative float-right w-4 cursor-pointer text-star-empty"
          onClick={() => {
            handleStarClick(true);
          }}
        >
          <span data-cy="starSR" className="sr-only">
            Favorite
          </span>
        </i>
      )}
    </div>
  );
};

export default Star;
