import React from "react";
import SearchBar from "../SearchBar/SearchBar";
import "./Header.scss";
import { useNavigate } from "react-router-dom";

const Header = ({
  title,
  searchPlaceHolder,
  handleSearch,
  noSearch,
  className,
  withBackground,
  handleSearchSubmit,
  user,
  dashboard = false,
  container,
  onTitleClick,
  isMobile,
  Ecommerce,
  isDirectory,
  errorMessage,
  newsroom,
  handleCloseNewsroom,
  newsroomWidth,
}) => {
  const navigate = useNavigate();
  const generateURLtitle = () => {
    switch (title) {
      case "Applied Company Store":
        return `/applied-shop/home`;
      default:
        return ``;
    }
  };
  return (
    <div
      data-cy="header-container"
      className="flex items-center justify-between pb-6 pt-8"
    >
      <h1
        className={`cursor-pointer text-[1.8em] font-normal text-secondary custom-lg:text-[1.8em] ${
          (withBackground ? "2lg:text-secondary" : "2lg:text-white",
          title === "Applied Newsroom"
            ? "2lg:[text-shadow:none]"
            : "2lg:[text-shadow:inherit]")
        }`}
        onClick={() => {
          onTitleClick ? navigate(generateURLtitle()) : () => {};
        }}
      >
        {title}
      </h1>
      <SearchBar
        className={className}
        noSearch={noSearch}
        withSearch={withBackground}
        withBackground={withBackground}
        handleSearch={handleSearch}
        handleSearchSubmit={handleSearchSubmit}
        searchPlaceHolder={searchPlaceHolder}
        user={user}
        dashboard={dashboard}
        container={container}
        isMobile={isMobile}
        Ecommerce={Ecommerce}
        isDirectory={isDirectory}
        errorMessage={errorMessage}
        newsroom={newsroom}
        handleCloseNewsroom={handleCloseNewsroom}
        newsroomWidth={newsroomWidth}
      />
    </div>
  );
};

export default Header;
