import React from "react";
import WorldClock from "../WorldClock/WorldClock";
import "./ClockOverlay.scss";
import { useTranslation } from "react-i18next";

const Overlay = ({ collapsed }) => {
  const { t } = useTranslation();
  return (
    <div
      // styleName="overlayContainer"
      className={`${
        collapsed ? "left-[70px]" : "left-[250px]"
      } absolute bottom-0 z-10 m-2 flex flex-col rounded-lg bg-[#00000080] pt-1 before:mx-[15px] before:my-[5px] before:border-x-0 before:border-b-[0.5px] before:border-t-0 before:border-solid before:text-left before:text-[1.2em] before:text-white before:content-['World_Clock']`}
    >
      <div
        // styleName="card"
        className="mx-2 my-1 w-[200px] rounded-[6px] p-[7px] text-white [&:last-child]:mb-2 [&_p]:mb-[0.1em] [&_p]:text-[1.1em] [&_p]:font-semibold [&_p]:tracking-[0.01em]"
      >
        <p>{t("RSM")}</p>
        <WorldClock timeZone={"America/Los_Angeles"} analog />
      </div>
      <div
        // styleName="card"
        className="mx-2 my-1 w-[200px] rounded-[6px] p-[7px] text-white [&:last-child]:mb-2 [&_p]:mb-[0.1em] [&_p]:text-[1.1em] [&_p]:font-semibold [&_p]:tracking-[0.01em]"
      >
        <p>{t("Amsterdam")}</p>
        <WorldClock timeZone={"Europe/Amsterdam"} analog />
      </div>
      <div
        // styleName="card"
        className="mx-2 my-1 w-[200px] rounded-[6px] p-[7px] text-white [&:last-child]:mb-2 [&_p]:mb-[0.1em] [&_p]:text-[1.1em] [&_p]:font-semibold [&_p]:tracking-[0.01em]"
      >
        <p>{t("Beirut")}</p>
        <WorldClock timeZone={"Asia/Beirut"} analog />
      </div>
      <div
        // styleName="card"
        className="mx-2 my-1 w-[200px] rounded-[6px] p-[7px] text-white [&:last-child]:mb-2 [&_p]:mb-[0.1em] [&_p]:text-[1.1em] [&_p]:font-semibold [&_p]:tracking-[0.01em]"
      >
        <p>{t("Brisbane")}</p>
        <WorldClock timeZone={"Australia/Brisbane"} analog />
      </div>
      <div
        // styleName="card"
        className="mx-2 my-1 w-[200px] rounded-[6px] p-[7px] text-white [&:last-child]:mb-2 [&_p]:mb-[0.1em] [&_p]:text-[1.1em] [&_p]:font-semibold [&_p]:tracking-[0.01em]"
      >
        <p>{t("New Zealand")}</p>
        <WorldClock timeZone={"Pacific/Auckland"} analog />
      </div>
      <div
        // styleName="card"
        className="mx-2 my-1 w-[200px] rounded-[6px] p-[7px] text-white [&:last-child]:mb-2 [&_p]:mb-[0.1em] [&_p]:text-[1.1em] [&_p]:font-semibold [&_p]:tracking-[0.01em]"
      >
        <p>{t("Queretaro")}</p>
        <WorldClock timeZone={"America/Mexico_City"} analog />
      </div>
      <div
        // styleName="card"
        className="mx-2 my-1 w-[200px] rounded-[6px] p-[7px] text-white [&:last-child]:mb-2 [&_p]:mb-[0.1em] [&_p]:text-[1.1em] [&_p]:font-semibold [&_p]:tracking-[0.01em]"
      >
        <p>{t("Tokyo")}</p>
        <WorldClock timeZone={"Asia/Tokyo"} analog />
      </div>
    </div>
  );
};

export default Overlay;
