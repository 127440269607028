import React from "react";
import "./Loading.scss";

const LoadingComponent = ({
  width = 50,
  height = 50,
  size = 50,
  centered = false,
  style,
  progress,
  isOrgChart = false,
}) => {
  return centered ? (
    <div
      className="[width:initial] supports-[display:grid]:grid"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: progress ? "scale(2)" : null,
        ...style,
      }}
    >
      <svg
        width={width || size}
        height={height || size}
        viewBox="25 25 50 50"
        className="m-auto block w-[3.75em] origin-center animate-rotate"
      >
        <circle
          className="animate-dash fill-none stroke-secondary [stroke-linecap:round] [stroke-width:2]"
          style={{ position: progress ? "absolute" : null }}
          cx="50"
          cy="50"
          r="20"
        />
      </svg>
    </div>
  ) : (
    <>
      <svg
        width={width || size}
        height={height || size}
        viewBox="25 25 50 50"
        className={`m-auto block w-[3.75em] origin-center ${isOrgChart ? "" : "animate-rotate"}`}
        style={style}
      >
        <circle
          className="animate-dash fill-none stroke-secondary [stroke-linecap:round] [stroke-width:2]"
          cx="50"
          cy="50"
          r="20"
        />
      </svg>
    </>
  );
};
export default LoadingComponent;
